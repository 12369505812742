import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Verification from "../components/Sections/Verification";

const ActivateUser = () => {
  return (
    <Layout>
        <SEO
            lang="ENG" 
            title="Tape Me"
            keywords={['badoo, tinder, TapeMe, love, person, geart, sex, date, Dating, datingsite, findlove, tapeme, voicemessage']}/>
        <Verification />
    </Layout>
  )
}

export default ActivateUser
