import React, { useState, useEffect } from "react";
import constants from "../../utils/constants";
import request from "../../utils/request";
import axios from "axios";

import "./Verification.scss";

const Verification = () => {
    const [processSucceed, setProcessSucceed] = useState(true);
    const [processDone, setProcessDone] = useState(false);

    useEffect(async () => {
        const token = window.location.search.split("=")[1];
        const endpoint = window.location.pathname.split("/")[1];
        const response = await request.postRequest(`/Auth/${endpoint}`, {
            VerificationToken: token
        });
        if (response) {
            setTimeout(() => {
                setProcessDone(true);
            }, 3000);
        } else {
            setTimeout(() => {
                setProcessSucceed(false);
            }, 3000);
        }
    }, []);

    return (
        <section id="verification-site" className="page-section">
            <div className="container position-relative zindex-5 text-center" style={{display: processSucceed && !processDone ? "" : "none"}}>
                <h1 className="title-verify">Processing your request</h1>
                <div className="lds-heart"><div></div></div>
            </div>
            <div className="container position-relative zindex-5 text-center" style={{display: processSucceed && processDone ? "" : "none"}}>
                <h1 className="title-verify">Account was successfuly activated</h1>
                <i className="fas fa-heart fa-4x error-icon"></i>
            </div>
            <div className="container position-relative zindex-5 text-center" style={{display: !processSucceed && !processDone ? "" : "none"}}>
                <h1 className="title-verify">Something went wrong, try again later or contact our support.</h1>
                <i className="fas fa-heart-broken fa-4x error-icon"></i>
            </div>
        </section>
    )
}

export default Verification